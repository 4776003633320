import React, { useState, useEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";

export default function Header({ bg_color, header_logo, tracking_url }) {
  const [display, setdisplay] = useState({ display: "none" });

  useEffect(() => {
    setTimeout(() => {
      setdisplay({});
    }, 2000);
  }, []);

  return (
    <div style={{ background: bg_color }} className="header-main">
      <Link to={"/"}>
        <img className="header-logo" src={header_logo} alt="logo" />
      </Link>
      <div style={{ minHeight: 42 }}>
        <div style={display}>
          <div id="google_translate_element">
            <img
              src={require("./../../Assets/Icons/globel.png")}
              className="globel_icon"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
