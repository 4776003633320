import React from "react";
import "./style.css";

export default function SocialLink({ configuration }) {
  const style = {
    color: configuration.font_color,
    padding: "0 5px",
  };

  return (
    <div className="footer-main">
      {configuration.fb_url && (
        <a target="_blank" rel="noreferrer" href={configuration.fb_url}>
          <i style={style} className="fab fa-facebook-f"></i>
        </a>
      )}
      {configuration.tw_url && (
        <a target="_blank" rel="noreferrer" href={configuration.tw_url}>
          <i style={style} className="fab fa-twitter"></i>
        </a>
      )}
      {configuration.in_url && (
        <a target="_blank" rel="noreferrer" href={configuration.in_url}>
          <i style={style} className="fab fa-instagram"></i>
        </a>
      )}
      {configuration.ln_url && (
        <a target="_blank" rel="noreferrer" href={configuration.ln_url}>
          <i style={style} className="fab fa-linkedin-in"></i>
        </a>
      )}
      {configuration.pt_url && (
        <a target="_blank" rel="noreferrer" href={configuration.pt_url}>
          <i style={style} className="fab fa-pinterest-p"></i>
        </a>
      )}
      {configuration.tk_url && (
        <a target="_blank" rel="noreferrer" href={configuration.tk_url}>
          <i style={style} className="fab fa-tiktok"></i>
        </a>
      )}
    </div>
  );
}
