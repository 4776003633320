import React from "react";
import { Helmet } from "react-helmet";

const HTML_Header = ({ configuration }) => {
  // const pageLanguage = `/en/${configuration.default_language}`;

  // setCookie('googtrans', '${pageLanguage}', 1);
  // function setCookie(key, value, expiry) {
  //     var expires = new Date();
  //     expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
  //     document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
  // }

  return (
    <Helmet>
      <title>{configuration.store_name}</title>
      <link rel="icon" type="image/*" href={configuration.icon_value} />
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.15.4/css/all.css"
      />
      <script
        type="text/javascript"
        src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      ></script>
      <script type="text/javascript">
        {`function googleTranslateElementInit() {
          new google.translate.TranslateElement({ pageLanguage: 'en'}, 'google_translate_element');
        }`}
      </script>
      {/* Global site tag (gtag.js) - Google Analytics */}
      {configuration.analytics_id && (
        <>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${configuration.analytics_id}`}
          ></script>
          <script>
            {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${configuration.analytics_id}');`}
          </script>
        </>
      )}
    </Helmet>
  );
};

export default HTML_Header;
