import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

// Style
import "./index.css";

// File
import App from "./App";
import store from "./Redux/store";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
