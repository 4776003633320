import axios from "axios";

const BASE_URL = {
  BASE_API_URL: "https://api.trackship.com/v1",
};

const API = {
  getTracking: "/public/tracking",
};

export const getTracking = () => {
  // Header
  const Header = {
    public: "4c9184f37cff01bcdc32dc486ec36961",
  };

  //get Setting
  const getTrackingData = async (body) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL.BASE_API_URL}${API.getTracking}`,
        body,
        {
          headers: {
            ...Header,
          },
        }
      );
      return data;
    } catch ({ response, message }) {
      return false;
    }
  };

  return {
    getTrackingData,
  };
};
