import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useSelector } from "react-redux";

// Import Style
import "./App.css";

// Import Pages
import NotFound from "./Pages/NotFound";
import Layout from "./Pages/Layout";

function App() {
  const isError = useSelector((state) => state.isError.isError);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout path="/" />,
    },
    {
      path: "/track",
      element: <Layout path="/track" />,
    },
    {
      path: "/track/:tracking_number",
      element: <Layout path="/track/:tracking_number" />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  const error = createBrowserRouter([
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  if (isError) {
    return <RouterProvider router={error} />;
  }

  return <RouterProvider router={router} />;
}

export default App;
