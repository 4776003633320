import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";

// Style
import "./style.css";

export default function Loder() {
  return (
    <div>
      <Skeleton height={90}></Skeleton>
      <Skeleton
        height={250}
        width={"40%"}
        style={{ marginLeft: "30%", marginTop: 100 }}
      ></Skeleton>
      <Skeleton
        height={50}
        width={"40%"}
        style={{ marginLeft: "30%", marginTop: 20 }}
      ></Skeleton>
      <Skeleton
        height={30}
        width={"20%"}
        style={{ marginLeft: "40%", marginTop: 100 }}
      ></Skeleton>
    </div>
  );
}
