import { createSlice } from "@reduxjs/toolkit";

const trackingSlice = createSlice({
  name: "tracking",
  initialState: {
    tracking: {},
  },
  reducers: {
    setTracking(state, action) {
      state.tracking = action.payload;
    },
  },
});

export const { setTracking } = trackingSlice.actions;
export default trackingSlice.reducer;
