import { configureStore } from "@reduxjs/toolkit";
import configReducer from "./Slice/configSlice";
import loaderReducer from "./Slice/loaderSlice";
import errorReducer from "./Slice/errorSlice";
import trackingReducer from "./Slice/trackingSlice";

const store = configureStore({
  reducer: {
    configuration: configReducer,
    loader: loaderReducer,
    isError: errorReducer,
    tracking: trackingReducer,
  },
});

export default store;
