import React from "react";
import { useSelector } from "react-redux";

// Style
import "./style.css";

// Component
import Title from "../../Component/Title";
import Form from "../../Component/Form";
import Branding from "../../Component/Branding";
import FooterLink from "../../Component/FooterLink";
import SocialLink from "../../Component/SocialLink";

export default function Home({ isTracking }) {
  const configuration = useSelector((state) => state.configuration.theme);

  return (
    <div>
      <Title
        text_color={configuration.font_color}
        title_text={configuration.form_heading_text}
      />
      <Form isTracking={isTracking} configuration={configuration} />
      <div>
        <FooterLink configuration={configuration} />
        {configuration.display_social_link === "enable" && (
          <SocialLink configuration={configuration} />
        )}
        {configuration.display_branding === "disable" ? null : <Branding />}
      </div>
    </div>
  );
}
