import React from "react";
import "./style.css";

export default function Branding({ title_text }) {
  return (
    <div className="branding-main">
      <div className="branding-text" >Powered by</div>
      <img
        className="branding-logo"
        src="https://trackship.com/wp-content/uploads/2022/08/TrackShip-Logo-641.png"
        alt="logo"
      />
    </div>
  );
}
