import React from "react";
import "./style.css";

export default function NotFound() {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img
        src={require('./../../Assets/Icons/404.png')}
        style={{
          width: 500,
        }}
      />
      <span
        style={{
          fontSize: 30,
          fontWeight: 600,
          color: "#545479",
          margin: "20px 0",
        }}
      >
        Oops! That page can’t be found.
      </span>
    </div>
  );
}
