import React from "react";
import "./style.css";

export default function Title({ title_text, text_color }) {
  return (
    <div className="title-main">
      <div style={{ background: "#124ed6" }} className="title-round"></div>
      <div style={{color: text_color}} className="title-text">{title_text}</div>
      <div style={{ background: "#ff6b29" }} className="title-round"></div>
    </div>
  );
}
