import axios from "axios";
import { setError } from "../Redux/Slice/errorSlice";

const BASE_URL = {
  BASE_API_URL: "https://api.trackship.com/v1",
};

const API = {
  getSetting: "/trackpage/store/settings",
};

export const getSetting = () => {

  // Header
  const Header = {
    public: "4c9184f37cff01bcdc32dc486ec36961",
  };

  //get Setting
  const getSiteData = async (body, dispatch) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL.BASE_API_URL}${API.getSetting}`,
        body,
        {
          headers: {
            ...Header,
          },
        }
      );
      return data;
    } catch ({ response, message }) {
      if (response.data.status === "error") {
        dispatch(setError(true));
      }
    }
  };

  return {
    getSiteData,
  };
};
