import React from "react";
import "./style.css";

export default function FooterLink({ configuration }) {
  return (
    <div className="footer-main">
      {configuration.footer_links &&
        JSON.parse(configuration.footer_links).map(({ title, url }, index) => {
          return (
            <div style={{ display: "flex" }}>
              <a style={{ color: configuration.link_color }} href={url}>
                {title}
              </a>
              {index ===
              JSON.parse(configuration.footer_links).length - 1 ? null : (
                <div
                  style={{ margin: "0 10px", color: configuration.link_color }}
                >
                  |
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
}
